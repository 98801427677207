import type { VariantProps} from 'class-variance-authority';

import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';

const buttonVariants = cva(
  'cursor-pointer',
  {
    defaultVariants: {
      size     : 'small',
      underline: 'none',
      variant  : 'gray',
    },
    variants: {
      size: {
        medium: 'text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
        small : 'text-caption-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
        xsmall: 'text-caption-3-regular xs:font-sfProLight sm:font-pretendardLight'
      },
      underline: {
        none     : 'border-b-0',
        underline: 'underline decoration-gray-60',
      },
      variant: {
        gray   : 'text-gray-60 active:text-gray-100 hover:text-gray-100',
        primary: 'text-point-blue-60 active:text-point-blue-100 hover:text-point-blue-100',
      },
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>,
 VariantProps<typeof buttonVariants> {
}

export const TextButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, disabled, size, underline, variant, ...props }, ref) => {
    return (
      <button 
        className={cn(
          buttonVariants({ size, underline, variant }),
          className,
          disabled && 'hover:cursor-default',
        )}
        disabled={disabled}
        name={props.name}
        onClick={props.onClick}
        ref={ref}
        value={props.value}
        {...props}
      />
    );
  });

TextButton.displayName = 'TextButton';